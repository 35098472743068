@use "@wellsky/atlas-ui/theming";
@use "styles/colors";
@use "styles/alerts.scss";

html,
body {
  height: 100%;
  background: colors.$color-page-background;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// mdc component styles
.mat-mdc-select-panel:not(.select-panel) {
  .mdc-list-item__primary-text {
    font-size: 14px !important;
  }
}

.mdc-menu-surface .mat-mdc-option mat-pseudo-checkbox {
  display: unset !important;
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
  background-color: #228189 !important;
  border-color: rgba(0, 0, 0, 0);
}

.mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
  padding-right: 0px !important;
}
