.alert-logic-step {
  .mat-mdc-form-field {
    .mat-mdc-select-arrow-wrapper {
      padding-top: 16px;
      path {
        display: none;
      }
    }
  }
  .rule-group-joiner {
    .mat-mdc-text-field-wrapper {
      background: transparent;
    }
    .joiner-select {
      max-width: 92px !important;
    }
  }
}

.alert-details-step {
  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      padding: 0;
      background: transparent;

      input {
        font-size: 14px !important;
      }
    }
    .mat-mdc-form-field-flex {
      padding-top: 0;
    }

    .mat-mdc-select-arrow-wrapper {
      padding-top: 16px;
      path {
        display: none;
      }
    }
  }

  .alert-details-message {
    .mat-mdc-text-field-wrapper {
      padding: 0 10.5px;
      padding-bottom: 3px;
      padding-top: 2px;

      .mat-mdc-form-field-infix {
        padding: 0 0 8px 0;
      }

      textarea {
        min-height: 136px;
      }
    }
  }
}

.severity-option {
  margin-top: 10px;
}

.severity-description {
  margin-top: 1px;
}

.mat-mdc-dialog-container {
  .mat-mdc-dialog-content {
    margin-left: 4px;
  }
  .mat-mdc-dialog-actions {
    margin-left: 12px;
    button:last-child {
      height: 35px;
      background-color: #228189;
      color: white;
      font-size: 14px;
      font-family: Roboto, sans-serif;
      border-radius: 3px;
      border: none;
      cursor: pointer;
    }
  }
}
